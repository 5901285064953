import React, { Fragment } from 'react';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useDevice from '../../api/hooks/useDevice';
import { useStore } from '../../store/store';

type Props = {
	pageType: 'candidate' | 'recruiter';
};
export default function Header({ pageType }: Props) {
	const { push } = useRouter();
	const { isPhone } = useDevice();
	const setShowAlertsSignupModal = useStore(
		state => state.setShowAlertsSignupModal,
	);

	const ABOUT_US_URL = process.env.NEXT_PUBLIC_WEBSITE_URL + 'about';

	const burgerMenuOptions = [
		{
			label: 'About us',
			onClick: () => {
				push(ABOUT_US_URL);
			},
			permission: true,
		},
		{
			label: 'Sign up for alerts',
			onClick: () => {
				setShowAlertsSignupModal(true);
			},
			permission: pageType === 'candidate',
		},
	];

	const LandingPageToggleButton = () => (
		<button
			onClick={() => {
				if (pageType === 'candidate') {
					return push('/recruiter');
				}
				push('/');
			}}
			className='p-1 px-4 mr-4 lg:mr-0 bg-slate-900 text-white rounded-full hover:bg-slate-500'
		>
			{pageType === 'candidate' ? 'Recruiting?' : 'Looking for a job?'}
		</button>
	);

	return (
		<div
			className='fixed flex-1 z-20 lg:top-2 h-20 lg:h-16 lg:mx-4 lg:rounded-full bg-pink-950 text-slate-900 flex items-center w-full lg:w-[calc(100%_-_2rem)]'
			id='MainNavBar'
		>
			{isPhone === false && (
				<div className='my-auto flex items-center text-base m-gap-4'>
					<Link href='/' passHref>
						<a>
							<img
								src='/img/StryveJobsLogo.svg'
								alt='stryve jobs logo'
								className='hover:opacity-80 w-32 h-12 min-w-32 relative'
							/>
						</a>
					</Link>

					<Link href={ABOUT_US_URL} passHref>
						<a>
							<p
								className='px-2 text-slate-900 hover:text-slate-500'
								id='About Us'
							>
								About Us
							</p>
						</a>
					</Link>
				</div>
			)}

			<div className='flex flex-1 items-center justify-between lg:justify-end bg-blueGrey-700 h-16 px-4 xl:pr-8 text-base'>
				{isPhone ? (
					<Fragment>
						<Link href='/' passHref>
							<a>
								<img
									src='/img/StryveJobsLogoMobile.svg'
									alt='stryve jobs logo'
									className='hover:opacity-80 w-18 h-8 min-w-12 relative'
								/>
							</a>
						</Link>

						<div className='relative'>
							<LandingPageToggleButton />
							<Menu>
								{({ open }) => (
									<Fragment>
										<Menu.Button aria-label='More'>
											<FontAwesomeIcon icon={faBars} size='lg' />
										</Menu.Button>
										<Transition
											enter='transition ease-out duration-100'
											enterFrom='transform opacity-0 scale-95'
											enterTo='transform opacity-100 scale-100'
											leave='transition ease-in duration-75'
											leaveFrom='transform opacity-100 scale-100'
											leaveTo='transform opacity-0 scale-95'
											show={open}
										>
											<Menu.Items className='z-999 origin-top-right absolute -right-2 mt-9 w-[calc(100vw_-_1rem)] bg-pink-950 rounded-lg shadow-pink ring-1 ring-black ring-opacity-5'>
												{burgerMenuOptions.map((option, index) => {
													return (
														option.permission === true && (
															<Menu.Item
																onClick={option.onClick}
																aria-label={option.label}
																key={`${option.label}-${index}`}
															>
																<span className='px-4 flex items-center text-left rounded-lg bg-pink-950 text-slate-900 hover:bg-lightBlue-100 w-full h-12'>
																	{option.label}
																</span>
															</Menu.Item>
														)
													);
												})}
											</Menu.Items>
										</Transition>
									</Fragment>
								)}
							</Menu>
						</div>
					</Fragment>
				) : (
					<Fragment>
						{pageType === 'candidate' && (
							<button
								onClick={() => {
									setShowAlertsSignupModal(true);
								}}
							>
								<p
									className='px-2 text-slate-900 hover:text-slate-500'
									id='alerts'
								>
									Sign up for alerts
								</p>
							</button>
						)}
						<LandingPageToggleButton />
					</Fragment>
				)}
			</div>
		</div>
	);
}
