import { AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { RecentJob } from '../../../types';
import { GetRecentJobsRequest } from '../requests/GetRecentJobsRequest';

type Props = {
	onError?: (error: AxiosError) => void;
};

export default function useRecentJobs({
	onError,
}: Props): QueryObserverResult<RecentJob[]> {
	return useQuery(
		'recentJobs',
		async () => {
			const { data } = await GetRecentJobsRequest();
			return data;
		},
		{
			retry: false,
			onError: (error: AxiosError) => {
				if (onError) onError(error);
			},
		},
	);
}
