import { faSearch } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useDevice from '../../api/hooks/useDevice';
import useRecentJobs from '../../api/hooks/useRecentJobs';
import { countries } from '../../utils/defaults';
import Button from '../Button/Button';
import { Input } from '../Input/Input';
import RecentJobPill from '../RecentJobPill/RecentJobPill';
import { SearchSelect } from '../SearchSelect/SearchSelect';

export default function CandidateLandingPage() {
	const { control, handleSubmit, errors } = useForm();
	const { push } = useRouter();
	const [fetchError, setFetchError] = useState(false);

	const { data: recentJobs } = useRecentJobs({
		onError: () => {
			setFetchError(true);
		},
	});

	const onSubmit = handleSubmit(
		(data: { jobTitle: string; country: string }) => {
			push(
				`/results/?term=${data.jobTitle}&filters[locationCountry]=${data.country}`,
			);
		},
	);

	const { isSmallest, isPhone, isMobile, isDesktop, isUHD } = useDevice();

	const getBGImageUrl = () => {
		if (isPhone)
			return '/img/StryveJobsBackgrounds/900×1600/candidateBackground.svg';
		if (isMobile)
			return '/img/StryveJobsBackgrounds/1000×1000/candidateBackground.svg';
		if (isDesktop)
			return '/img/StryveJobsBackgrounds/1920x1080/candidateBackground.svg';
		if (isUHD)
			return '/img/StryveJobsBackgrounds/3840×2160/candidateBackground.svg';
	};

	return (
		<div className='w-full min-h-screen pt-28 md:pt-0 flex justify-center items-center relative'>
			{/* <BubbleBackground
				alwaysShow
				background='bg-green-900'
				colours={[
					'#f8b8c3', //pink cupid 900
					'#ffd128', //yellow turbo 900
					'#ff2868', //red 900
					'#43dbdb', //turquoise 900
					'#ff9c28', //orange 900
					'#0f00c0', //darkBlue 900
					'#009aff', //lightBlue 900
				]}
				bubbleCount={8}
				margin={5}
				minR={15}
				maxR={90}
			/> */}

			<Image
				src={getBGImageUrl()}
				layout='fill'
				objectFit='cover'
				quality={100}
				alt='Green background with multicoloured circles, some of which include smiling faces.'
			/>

			<div className='h-full md:h-auto w-5/6 xl:max-w-[50%] z-10 flex flex-col m-gap-2 md:px-4 md:py-8 md:rounded-lg md:bg-white/80 text-white md:text-slate-900'>
				<h1 className='leading-tight text-2xl lg:text-3xl font-light my-0 py-0 pr-7 md:pr-0'>
					Stryve for a <strong>brighter future</strong>.
				</h1>
				<h1 className='leading-tight text-xl lg:text-2xl font-light my-0 py-0'>
					Search for your dream role now.
				</h1>

				<form
					className={classNames(
						'flex flex-col md:flex-row gap-4 text-slate-900 pt-4',
						Object.keys(errors).length > 0 ? 'items-start' : 'items-center',
					)}
				>
					<Controller
						name='jobTitle'
						defaultValue=''
						control={control}
						render={props => (
							<Input
								label='Job Title'
								type='text'
								onChange={value => {
									props.onChange(value);
								}}
								value={props.value}
								name='title'
								placeholder='Please enter a job title'
								extraClasses='text-slate-900 w-full'
								error={errors?.jobTitle?.message}
								white
							/>
						)}
					/>
					<Controller
						name='country'
						defaultValue='GB'
						control={control}
						rules={{
							required: 'Please select a country',
						}}
						render={props => (
							<SearchSelect
								onChange={value => {
									if (value === null) return props.onChange(null);
									props.onChange(value.value);
								}}
								options={countries}
								placeholder='Please enter a country'
								name='country'
								value={props.value}
								label='Country'
								isClearable={true}
								white
								error={errors?.country?.message}
								required
							/>
						)}
					/>
					<div className='flex items-center h-16'>
						<Button
							onClick={onSubmit}
							ariaLabel='Search'
							icon={faSearch}
							thin={isSmallest}
							className='w-full md:w-max'
							type='submit'
						>
							Search
						</Button>
					</div>
				</form>

				{recentJobs !== undefined &&
					recentJobs?.length > 0 &&
					fetchError === false && (
						<Fragment>
							<h2 className='text-xl font-semibold'>Latest Roles</h2>
							<div className='flex flex-col md:flex-row flex-wrap gap-4'>
								{recentJobs?.map(job => (
									<RecentJobPill job={job} key={job._id} />
								))}
							</div>
						</Fragment>
					)}
			</div>
		</div>
	);
}
