import React, { ReactNode } from 'react';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { toast } from 'react-toastify';
import GenericToast from './GenericToast';

export const errorToast = (message: ReactNode = 'Something went wrong') => {
	toast(
		() => <GenericToast icon={faExclamationCircle}>{message}</GenericToast>,
		{
			type: 'error',
			position: 'bottom-right',
			hideProgressBar: true,
			icon: false,
			toastId: 'error',
		},
	);
};
