import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

export type RecentJobPillProps = {
	job: {
		_id: string;
		title: string;
		jobBoardLink: string;
		putLiveAt: string;
		entity: {
			name: string;
			logoThumbnail: string;
		};
	};
	white?: boolean;
};

export default function RecentJobPill({
	job,
	white = true,
}: RecentJobPillProps) {
	const { push } = useRouter();
	const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL || '';

	return (
		<button
			onClick={() => {
				window.open(job.jobBoardLink);
			}}
			className={classNames(
				'w-full md:w-max md:min-w-48 flex items-center justify-between m-gap-4  text-slate-900 text-base rounded-full py-2 pl-5 pr-4',
				{
					'bg-white hover:bg-pink-500 active:bg-lightBlue-100': white,
					'bg-pink-900 hover:bg-pink-500 active:bg-lightBlue-100': !white,
				},
			)}
		>
			<div className='flex flex-col'>
				<p className='font-semibold text-left'>{job.title}</p>
				<p className='font-thin text-left'>{job.entity.name}</p>
			</div>
			{job.entity.logoThumbnail && (
				<img
					src={CDN_URL + `/${job.entity.logoThumbnail}`}
					alt='Thumbnail'
					className='w-10 h-10 rounded-full'
				/>
			)}
		</button>
	);
}
