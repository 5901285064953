import { api } from '../ApiHandler';
import { RecentJob } from '../../../types';

export const GetRecentJobsRequest = (): Promise<{
	data: RecentJob;
}> => {
	return api()
		.get(`/jobs/recent`)
		.then(response => response.data);
};
