import React from 'react';
import Head from 'next/head';
import { setCookie } from 'cookies-next';
import GATracker from '../src/components/GATracker/GATracker';
import Header from '../src/components/Header/Header';
import CandidateLandingPage from '../src/components/LandingPage/CandidateLandingPage';
import { errorToast } from '../src/components/Toast/errorToast';
import useQueryString from '../utils/useQueryString';
import Screen from '../src/components/Screen/Screen';
import makeFullURL from '../src/utils/makeFullURL';

export default function LandingPage() {
	const errorRedirect = useQueryString('404');
	setCookie('showaddToTalentPoolToggle', true, {
		maxAge: 30 * 60, // 30 minutes
		sameSite: 'strict',
	});

	return (
		<Screen>
			<Head>
				<title>Stryve Jobs</title>
				<meta
					property='og:title'
					content='Stryve Jobs – Find Your Next Job Today'
					name='title'
				/>
				<meta
					property='og:description'
					content='Find the right candidates with the perfect skills and experience to help your business thrive. Looking for more? Talk to us about our revolutionary ATS.'
					name='og:description'
				/>
				<meta
					property='og:url'
					content='https://jobs.stryve.online'
					name='og:url'
				/>
				<meta property='og:type' content='website' name='og:type' />
				<meta
					property='og:image'
					content={makeFullURL('img/StryveJobsOGImage.png')}
					name='og:image'
				/>
			</Head>
			<GATracker />
			<div className='w-screen min-h-screen h-full flex justify-center bg-white'>
				<Header pageType='candidate' />
				<CandidateLandingPage />
				<a
					href='https://www.stryve.online/b-corporation'
					target='_blank'
					rel='noreferrer'
					className='absolute top-[80px] right-2'
				>
					<img
						src={makeFullURL('img/BCorp.png')}
						height='150'
						width='70'
						alt='bcorp logo'
					/>
				</a>
				{errorRedirect &&
					errorToast(`We couldn't find the page you were looking for.`)}
			</div>
		</Screen>
	);
}
